<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary" v-if="Object.keys(this.value).length===0">
        <v-toolbar-title><h4 class="font-weight-light">LOAN APPLICATION REQ.</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="4"
              cols="12"

            >
              <v-text-field
                v-if="Object.keys(this.value).length===0"
                readonly
                v-model="branch"
                label="Branch"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                type="date"
                v-model="date_accomplished"
                label="Date Accomplished"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                @keyup="loan_counter_data"
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                v-model="laf_no"
                label="LAF No."
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              md="12"
              cols="12"
            >
              <v-card>
                <v-card-text>
                  <v-col
                    md="12"
                    cols="12"
                    v-if="Object.keys(this.value).length===0"
                  >
                    <v-combobox
                      class="mx-2"
                      :append-icon="icons.mdiAccountSearchOutline"
                      v-model="search"
                      @keyup="searching($event.target.value)"
                      @change="get_search_items_info"
                      :items="search_items"
                      item-value="id"
                      item-text="name"
                      placeholder="Search by (Lastname/Firstname/CMF #)"
                      ref="memberCombobox"
                      dense
                      outlined
                    ></v-combobox>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  ></v-col>
                  <v-row>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="last_name"
                        label="Last Name"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="first_name"
                        label="First Name"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="middle_name"
                        label="Middle Name"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="12"
                   cols="12">
              <LoansCalculator
                :key="this.keyLoansCalculator"
                :is_approved="true"
                :value="this.value"
                :member_to_loan="this.member_to_loan"
                :can_edit="true"
                v-on:data="calculation_of_loan"
              ></LoansCalculator>
            </v-col>
            <v-col md="12"
                   cols="12">
              <v-card
                flat
              >
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h4 class="font-weight-light">CO-MAKER INFOMATION</h4>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text v-if="Object.keys(this.value).length===0">
                  <v-col
                    md="12"
                    cols="12"
                  >
                    <v-combobox
                      class="mx-2"
                      :append-icon="icons.mdiAccountSearchOutline"
                      v-model="search_co"
                      @keyup="searching_co($event.target.value)"
                      @change="get_search_items_info_co"
                      :items="search_items_co"
                      item-value="id"
                      item-text="name"
                      placeholder="Search by (Lastname/Firstname/CMF #)"
                      ref="memberCombobox2"
                      dense
                      outlined
                    ></v-combobox>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  ></v-col>
                  <v-row>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="last_name_co"
                        label="Co-Maker Last Name"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="first_name_co"
                        label="Co-Maker First Name"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="middle_name_co"
                        label="Co-Maker Middle Name"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        readonly
                      ></v-text-field>
                    </v-col>


                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="permanent_address_co"
                        dense
                        outlined
                        label="Permanent Address"
                        :rules="rules.default_max_255_character_and_no_empty_rule"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-else>
                  <v-col
                    md="4"
                    cols="12"
                  ></v-col>
                  <v-row>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="last_name_co"
                        label="Co-Maker Last Name"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="first_name_co"
                        label="Co-Maker First Name"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="middle_name_co"
                        label="Co-Maker Middle Name"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="permanent_address_co"
                        dense
                        outlined
                        label="Permanent Address"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="Object.keys(this.value).length>0"
                      cols="12"
                      md="6"
                    >
                      <v-select
                        :items="collectors_items"
                        item-value="id"
                        item-text="name"
                        label="Assigned Collector By"
                        required
                        :rules="rules.combobox_rule"
                        v-model="collector_id"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>
            <v-checkbox
              v-model="is_with_signature"
              label="With Signature"
              class="mt-0"
              hide-details
            ></v-checkbox>
            <v-col cols="12" v-if="position==='ADMIN'">

              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="update_loan_application"
                v-if="!saving"
              >
                Update changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
            <v-col cols="12" v-if="position!='ADMIN' && Object.keys(this.value).length===0">

              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="save_loan_application"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import 'croppie/croppie.css'// import the croppie css manually
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import LoansCalculator from '@/views/create_interface/LoansData'

  export default {
    props: {
      value: Object
    },
    components: {
      snackBarDialog,
      LoansCalculator,
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/avatars/1.png'),
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    created() {
      this.loan_counter_data()
    },
    data() {
      return {
        keyLoansCalculator: 0,
        type_of_loan_items: [],
        loan_insurance: 0,
        loan_insurance_comm: '0',
        loan_insurance_addtnl: '0',
        loan_processing_fee: '0',
        mortuary_insurance: '250',
        share_capital: '0',
        nmapi: '0',
        foundation: '50',
        regular_savings: '0',
        mbai: '0',

        saving: false,
        alert: false,
        alert_message: '',
        croppieImage: '',
        cropped: this.avatarImg,
        croppieImage_bus: '',
        cropped_bus: this.avatarImg,
        croppieImage_home: '',
        cropped_home: this.avatarImg,

        search: '',
        search_member: '',
        search_items: [],

        search_co: '',
        search_member_co: '',
        search_items_co: [],

        collectors_items: [],
        collector_id: 0,

        date_accomplished: '',
        laf_no: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        date_of_birth: '',
        place_of_birth: '',
        height: '',
        weight: '',
        tin_no: '',
        sss_no: '',
        type_of_id: '',
        id_no: '',
        civil_status: '',
        no_of_dependents: '',
        gender: '',
        present_address: '',
        residence_is: '',
        residence_specify: '',
        permanent_address: '',
        religion: '',
        citizenship: '',
        contact_no: '',
        email_address: '',
        occupation: '',
        employment_date: '',
        employer: '',
        employer_address: '',
        employer_contact_no: '',
        monthly_income: '',
        employment_status: '',
        family_last_name: '',
        family_first_name: '',
        family_middle_name: '',
        family_date_of_birth: '',
        family_contact_no: '',
        family_occupation: '',
        family_employer: '',
        family_employer_address: '',
        family_monthly_income: '',
        family_employment_status: '',
        family_employment_date: '',
        is_with_signature: true,

        purpose_of_loan: '',
        type_of_loan: '',
        type_of_loan_others: '',
        loan_amount: '',
        mode_of_loan: '',
        terms: '',
        due_amount: '',
        interest: '',
        interestt: 0,
        due_interest: 0,
        loan_savings: '',
        loan_savingss: '',
        total: '',
        totall: '',
        ci_items: [],
        ci_id: 0,
        amount_due: 0,
        due_loan_savings: '',

        last_name_co: '',
        first_name_co: '',
        middle_name_co: '',
        type_of_collateral: '',
        specify_collateral: '',
        permanent_address_co: '',
        total_net: '0',

        member_to_loan: {},
      }
    },
   async mounted() {
      if (Object.keys(this.value).length > 0) {
       await this.loan_initialize_data({
          branch_id: this.branch_id,
        })
          .then(response => {
            this.collectors_items = response.data[0].collectors_data
          })
          .catch(error => {
            this.saving = false
          })
        this.collector_id = this.value.collector_id
        this.last_name = this.value.members.last_name
        this.first_name = this.value.members.first_name
        this.middle_name = this.value.members.middle_name
        this.type_of_loan = this.value.type_of_loan
        this.loan_amount = this.value.amount
        this.type_of_loan_others = this.value.specify_type_of_loan
        this.mode_of_loan = this.value.mode_of_loan
        this.terms = this.value.terms + ' month/s'
        this.purpose_of_loan = this.value.purpose_of_loan
        this.laf_no = this.value.laf_no
        this.share_capital = this.value.share_capital
        this.regular_savings = this.value.regular_savings
        this.mbai = this.value.mbai
        this.member_to_loan = this.value.members
        // this.date_accomplished = moment(this.value.date_accomplished, 'MMMM D, YYYY').format('YYYY-MM-DD')
        this.date_accomplished = moment().format('YYYY-MM-DD')
        if (this.value.co_maker != null) {
          this.last_name_co = this.value.co_maker.last_name
          this.first_name_co = this.value.co_maker.first_name
          this.middle_name_co = this.value.co_maker.middle_name
          this.permanent_address_co = this.value.co_maker.permanent_address
        }
        this.keyLoansCalculator++
      }
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member == '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
      search_member_co: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member_co == '') {
            this.search_items_co = []
          } else {
            this.searching_search_items_co(this.search_member_co)
          }
        }, 500)
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['month_start', 'month_end', 'month_of', 'branch', 'branch_id', 'user_id', 'name', 'position']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('branch', ['get_user_branch']),
      ...mapActions('members_information', ['search_members_paid', 'search_members']),
      ...mapActions('loans_data', ['loan_initialize_data', 'is_member_have_unpaid_loans', 'register_new_loan', 'reconstruct_loan', 'type_of_loan_list']),
      reset() {

        this.loan_insurance = '0'
        this.loan_processing_fee = '0'
        this.mortuary_insurance = '250'
        this.share_capital = '0'
        this.regular_savings = '0'
        this.mbai = '0'
        this.foundation = '50'

        this.saving = false
        this.alert = false
        this.alert_message = ''
        this.croppieImage = ''
        this.cropped = this.avatarImg

        this.croppieImage_bus = ''
        this.cropped_bus = this.avatarImg

        this.croppieImage_home = ''
        this.cropped_home = this.avatarImg

        this.search = ''
        this.search_member = ''
        this.search_items = []


        this.laf_no = ''
        this.last_name = ''
        this.first_name = ''
        this.middle_name = ''
        this.date_of_birth = ''
        this.place_of_birth = ''
        this.height = ''
        this.weight = ''
        this.tin_no = ''
        this.sss_no = ''
        this.type_of_id = ''
        this.id_no = ''
        this.civil_status = ''
        this.no_of_dependents = ''
        this.gender = ''
        this.present_address = ''
        this.residence_is = ''
        this.residence_specify = ''
        this.permanent_address = ''
        this.religion = ''
        this.citizenship = ''
        this.contact_no = ''
        this.email_address = ''
        this.occupation = ''
        this.employment_date = ''
        this.employer = ''
        this.employer_address = ''
        this.employer_contact_no = ''
        this.monthly_income = ''
        this.employment_status = ''
        this.family_last_name = ''
        this.family_first_name = ''
        this.family_middle_name = ''
        this.family_date_of_birth = ''
        this.family_contact_no = ''
        this.family_occupation = ''
        this.family_employer = ''
        this.family_employer_address = ''
        this.family_monthly_income = ''
        this.family_employment_status = ''
        this.family_employment_date = ''
        this.is_with_signature = true

        this.purpose_of_loan = ''
        this.type_of_loan = ''
        this.type_of_loan_others = ''
        this.loan_amount = ''
        this.mode_of_loan = ''
        this.terms = ''
        this.due_amount = ''
        this.interest = ''
        this.loan_savings = ''
        this.total = ''
        this.ci_items = []
        this.ci_id = 0
        this.amount_due = 0
        this.due_loan_savings = ''
        this.due_date = ''


        this.loan_counter_data()
      },
      resett2() {
        this.last_name_co = ''
        this.first_name_co = ''
        this.middle_name_co = ''
        this.permanent_address_co = ''

        this.search_co = ''
        this.search_member_co = ''
        this.search_items_co = []
      },
      croppie(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped = this.croppieImage = e.target.result
        };

        reader.readAsDataURL(files[0]);
      },
      croppie_bus(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped_bus = this.croppieImage_bus = e.target.result
        };

        reader.readAsDataURL(files[0]);
      },
      croppie_home(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped_home = this.croppieImage_home = e.target.result
        };

        reader.readAsDataURL(files[0]);
      },
      resett() {
        this.$refs.refInputEl.value = ''
        this.cropped = this.avatarImg

        this.$refs.refInputEl_bus.value = ''
        this.cropped_bus = this.avatarImg

        this.$refs.refInputEl_home.value = ''
        this.cropped_home = this.avatarImg
      },
      loan_counter_data() {
        this.keyLoansCalculator++
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      calculation_of_loan(data) {
        this.type_of_loan = data.type_of_loan
        this.type_of_loan_others = data.type_of_loan_others
        this.cropped_bus = data.cropped_bus
        this.purpose_of_loan = data.purpose_of_loan
        this.loan_amount = data.loan_amount
        this.mode_of_loan = data.mode_of_loan
        this.terms = data.terms
        this.due_amount = data.due_amount
        this.interest = data.interest
        this.loan_savings = data.loan_savings
        this.total = data.total
        this.ci_id = data.ci_id
        this.loan_insurance = data.loan_insurance
        this.loan_processing_fee = data.loan_processing_fee
        this.mortuary_insurance = data.mortuary_insurance
        this.foundation = data.foundation
        this.nmapi = data.nmapi
        this.share_capital = data.share_capital
        this.regular_savings = data.regular_savings
        this.mbai = data.mbai
        this.total_net = data.total_net
        this.laf_no = data.laf_no
        this.due_date = data.due_date
        this.totall = data.totall
        this.interestt = data.interestt
        this.loan_savingss = data.loan_savingss
        this.type_of_collateral = data.type_of_collateral
        this.specify_collateral = data.specify_collateral
      },
      searching(value) {
        this.search_member = value
      },
      searching_co(value) {
        this.search_member_co = value
      },
      searching_search_items(value) {
        this.member_to_loan = {}
        this.search_members_paid({
          branch_id: this.branch_id,
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      searching_search_items_co(value) {
        this.search_members_paid({
          branch_id: this.branch_id,
          search_word: value
        })
          .then(response => {
            this.search_items_co = response.data
          })
      },
      get_search_items_info() {
        this.member_to_loan = {}
        this.amount = 0
        this.amount_due = []
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(this.search.id)
          if (index != -1) {
            this.last_name = this.search_items[index].member.last_name
            this.first_name = this.search_items[index].member.first_name
            this.middle_name = this.search_items[index].member.middle_name
            this.place_of_birth = this.search_items[index].member.place_of_birth
            this.tin_no = this.search_items[index].member.tin_no
            this.sss_no = this.search_items[index].member.sss_no
            this.civil_status = this.search_items[index].member.civil_status
            this.no_of_dependents = this.search_items[index].member.no_of_dependents
            this.gender = this.search_items[index].member.gender
            this.present_address = this.search_items[index].member.present_address
            this.residence_specify = this.search_items[index].member.residence_specify
            this.permanent_address = this.search_items[index].member.permanent_address
            this.religion = this.search_items[index].member.religion
            this.citizenship = this.search_items[index].member.citizenship
            this.contact_no = this.search_items[index].member.contact_no
            this.email_address = this.search_items[index].member.email_address
            this.occupation = this.search_items[index].member.occupation
            this.employment_date = this.search_items[index].member.employment_date
            this.employer = this.search_items[index].member.employer
            this.employer_address = this.search_items[index].member.employer_address
            this.employer_contact_no = this.search_items[index].member.employer_contact_no
            this.family_last_name = this.search_items[index].member.family_last_name
            this.family_first_name = this.search_items[index].member.family_first_name
            this.family_middle_name = this.search_items[index].member.family_middle_name
            this.family_contact_no = this.search_items[index].member.family_contact_no
            this.family_occupation = this.search_items[index].member.family_occupation
            this.family_employer = this.search_items[index].member.family_employer
            this.family_employer_address = this.search_items[index].member.family_employer_address
            this.family_monthly_income = this.search_items[index].member.family_monthly_income
            this.family_employment_status = this.search_items[index].member.family_employment_status
            this.family_employment_date = this.search_items[index].member.family_employment_date
            this.$refs.form.validate()
            this.member_to_loan = this.search_items[index].member
            this.keyLoansCalculator++
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },
      get_search_items_info_co() {
        if (this.search_items_co.length > 0) {
          var index = this.search_items_co.map(function (x) {
            return x.id;
          }).indexOf(this.search_co.id)
          if (index != -1) {
            this.last_name_co = this.search_items_co[index].member.last_name
            this.first_name_co = this.search_items_co[index].member.first_name
            this.middle_name_co = this.search_items_co[index].member.middle_name
            this.permanent_address_co = this.search_items_co[index].member.permanent_address
          } else {
            this.resett2()
          }
        } else {
          this.resett2()
        }
      },
      update_loan_application() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          if (Object.keys(this.value).length > 0) {
            const data = new FormData()
            data.append('id', this.value.id);
            data.append('last_name', this.last_name);
            data.append('first_name', this.first_name);
            data.append('middle_name', this.middle_name);
            data.append('date_accomplished', moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY'));
            data.append('due_date', this.due_date);
            data.append('type_of_loan', this.type_of_loan);
            data.append('purpose_of_loan', this.purpose_of_loan);
            data.append('mode_of_loan', this.mode_of_loan);
            var splited_terms_loans = this.terms.split(' ')
            data.append('terms', splited_terms_loans[0]);
            data.append('amount', this.loan_amount);
            data.append('share_capital', this.share_capital);
            data.append('regular_savings', this.regular_savings);
            data.append('mbai', this.mbai);
            data.append('interest', this.interestt);
            data.append('due_interest', this.due_interest);
            data.append('loan_savings', this.loan_savingss);
            data.append('due_loan_savings', this.due_loan_savings);
            data.append('total', this.totall);
            data.append('amount_due', this.amount_due);
            data.append('month_of', this.month_of);
            data.append('created_id', this.user_id);
            data.append('specify_type_of_loan', this.type_of_loan_others.toUpperCase());
            data.append('type_of_collateral', this.type_of_collateral);
            data.append('specify_collateral', this.specify_collateral);
            data.append('loan_insurance', this.loan_insurance);
            data.append('processing_fee', this.loan_processing_fee);
            data.append('mortuary', this.mortuary_insurance);
            data.append('foundation', this.foundation);
            this.reconstruct_loan(data)
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: 'warning',
                  text: response.data,
                })
                this.print_data(this.value.branch_id)
                this.$emit('data')
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                console.log(error)
                this.saving = false
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      save_loan_application() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          if (Object.keys(this.value).length === 0) {

            var flag = false
            if (moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY') === moment(this.month_start, 'MMMM D, YYYY').format('MMMM D, YYYY')
              || moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY') === moment(this.month_end, 'MMMM D, YYYY').format('MMMM D, YYYY')) {
              flag = true
            } else {
              var compareDate = moment(this.date_accomplished, 'YYYY-MM-DD')
              var startDate = moment(this.month_start, 'MMMM D, YYYY')
              var endDate = moment(this.month_end, 'MMMM D, YYYY')

              flag = compareDate.isBetween(startDate, endDate) //false in this case
            }
            if (flag === false) {
              this.date_accomplished = ''
              this.alert = true
              this.alert_message = 'Date Accomplished is Not Belong in Transaction Month!'
              this.saving = false
            } else {
              var proceed = true;
              if (proceed) {
                const data = new FormData()
                data.append('member_id', this.search.id);
                data.append('type_of_loan', this.type_of_loan);
                this.is_member_have_unpaid_loans(data)
                  .then(response => {
                    if (response.data === 1) {
                      this.alert = true
                      this.alert_message = 'Member Already Have Unpaid Loan!'
                      this.saving = false
                    } else {
                      data.append('created_id', this.user_id);
                      data.append('loan_insurance_comm', this.loan_insurance_comm);
                      data.append('loan_insurance_addtnl', this.loan_insurance_addtnl);
                      data.append('loan_insurance', this.loan_insurance);
                      data.append('loan_processing_fee', this.loan_processing_fee);
                      data.append('mortuary_insurance', this.mortuary_insurance);
                      data.append('share_capital', this.share_capital);
                      data.append('regular_savings', this.regular_savings);
                      data.append('mbai', this.mbai);
                      data.append('foundation', this.foundation);
                      data.append('nmapi', this.nmapi);
                      data.append('type_of_collateral', this.type_of_collateral);
                      data.append('specify_collateral', this.specify_collateral);

                      data.append('last_name', this.last_name);
                      data.append('first_name', this.first_name);
                      data.append('middle_name', this.middle_name);
                      data.append('branch_id', this.branch_id);
                      data.append('laf_no', this.laf_no);
                      data.append('date_accomplished', moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY'));
                      data.append('due_date', this.due_date);
                      data.append('specify_type_of_loan', this.type_of_loan_others.toUpperCase());
                      data.append('type_of_loan', this.type_of_loan);
                      data.append('purpose_of_loan', this.purpose_of_loan);
                      data.append('mode_of_loan', this.mode_of_loan);
                      var splited_terms_loans = this.terms.split(' ')
                      data.append('terms', splited_terms_loans[0]);
                      data.append('amount', this.loan_amount);
                      data.append('interest', this.interestt);
                      data.append('due_interest', this.due_interest);
                      data.append('loan_savings', this.loan_savingss);
                      data.append('due_loan_savings', this.due_loan_savings);
                      data.append('total', this.totall);
                      data.append('amount_due', this.amount_due);
                      data.append('month_of', this.month_of);
                      data.append('collector_id', this.collector_id);
                      data.append('loan_insurance', this.loan_insurance);
                      data.append('processing_fee', this.loan_processing_fee);
                      data.append('mortuary', this.mortuary_insurance);
                      data.append('foundation', this.foundation);

                      data.append('valid_id_picture', this.croppieImage);
                      data.append('business_permit', this.croppieImage_bus);
                      data.append('home_skecth', this.croppieImage_home);

                      data.append('date_accomplished', moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY'));
                      data.append('date_of_birth', moment(this.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'));
                      data.append('place_of_birth', this.place_of_birth);
                      data.append('height', this.height);
                      data.append('weight', this.weight);
                      data.append('tin_no', this.tin_no);
                      data.append('sss_no', this.sss_no);
                      data.append('specify_valid_id', this.type_of_id);
                      data.append('valid_id_no', this.id_no);
                      data.append('civil_status', this.civil_status);
                      data.append('no_of_dependents', this.no_of_dependents);
                      data.append('gender', this.gender);
                      data.append('present_address', this.present_address);
                      data.append('religion', this.religion);
                      data.append('residence', this.residence_is);
                      data.append('residence_others', this.residence_specify);
                      data.append('permanent_address', this.permanent_address);
                      data.append('citizenship', this.citizenship);
                      data.append('contact_no', this.contact_no);
                      data.append('email_address', this.email_address);
                      data.append('occupation', this.occupation);
                      data.append('type_of_loan', this.type_of_loan);
                      data.append('employment_date', this.employment_date);
                      data.append('employer', this.employer);
                      data.append('employer_address', this.employer_address);
                      data.append('employer_contact_no', this.employer_contact_no);
                      data.append('monthly_income', this.monthly_income);
                      data.append('employment_status', this.employment_status);
                      data.append('family_last_name', this.family_last_name);
                      data.append('family_first_name', this.family_first_name);
                      data.append('family_middle_name', this.family_middle_name);
                      data.append('family_date_of_birth', moment(this.family_date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'));
                      data.append('family_contact_no', this.family_contact_no);
                      data.append('family_occupation', this.family_occupation);
                      data.append('family_employer', this.family_employer);
                      data.append('family_employer_address', this.family_employer_address);
                      data.append('family_monthly_income', this.family_monthly_income);
                      data.append('family_employment_status', this.family_employment_status);
                      data.append('family_employment_date', this.family_employment_date);

                      data.append('co_maker_id', this.search_co.id);
                      data.append('ci_by_id', this.ci_id);
                      var with_signature = 0
                      if (this.is_with_signature) {
                        with_signature = 1
                      }
                      data.append('is_with_signature', with_signature);
                      this.register_new_loan(data)
                        .then(response => {
                          this.change_snackbar({
                            show: true,
                            color: 'success',
                            text: response.data,
                          })
                          this.reset()
                          this.resett2()
                        })
                        .catch(error => {
                          this.alert = true
                          this.alert_message = error
                          console.log(error)
                          this.saving = false
                        })
                    }
                  })
                  .catch(error => {
                    this.alert = true
                    this.alert_message = error
                    this.saving = false
                  })
              }
            }
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      print_data() {
        var branchcc = this.value.branch.branch_code
        var branchcc_add = this.value.branch.address
        var branchcc_cont = this.value.branch.contact_no
        var imgData = this.company_logo
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936
          },
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: branchcc_add, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + branchcc_cont,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LOAN REQUEST APPROVED', style: 'title'},
            '================================================================================',
            ' ',
            {
              stack: [
                {
                  columns: [
                    {text: 'Branch', style: 'main_info1'},
                    {text: 'Date Accomplished', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: branchcc, style: 'main_info'},
                    {
                      text: moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                      style: 'main_info'
                    },
                  ]
                },
              ]
            },
            ' ',
            'PERSONAL INFORMATION',
            {
              stack: [
                {
                  columns: [
                    {text: 'Last Name', style: 'main_info1'},
                    {text: 'First Name', style: 'main_info1'},
                    {text: 'Middle Name', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.last_name, style: 'main_info'},
                    {text: this.first_name, style: 'main_info'},
                    {text: this.middle_name, style: 'main_info'},
                  ]
                },
              ]
            },
            ' ',
            'LOAN DATA',
            {
              stack: [
                {
                  columns: [
                    {text: 'Type of Loan', style: 'main_info1'},
                    {text: 'Specify', style: 'main_info1'},
                    {text: 'Type of Collateral', style: 'main_info1'},
                    {text: 'Specify Collateral', style: 'main_info1'},
                    {text: 'Purpose of Loan', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.type_of_loan, style: 'main_info'},
                    {text: this.type_of_loan_others, style: 'main_info'},
                    {text: this.type_of_collateral, style: 'main_info'},
                    {text: this.specify_collateral, style: 'main_info'},
                    {text: this.purpose_of_loan, style: 'main_info'},
                  ]
                },
                {
                  columns: [
                    {text: 'Loan Amount', style: 'main_info1'},
                    {text: 'Mode of Loan', style: 'main_info1'},
                    {text: 'Terms of Loan', style: 'main_info1'},
                    {text: 'Due Amount', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.formatPrice(this.loan_amount), style: 'main_info'},
                    {text: this.mode_of_loan, style: 'main_info'},
                    {text: this.terms, style: 'main_info'},
                    {text: this.due_amount, style: 'main_info'},
                  ]
                },
                {
                  columns: [
                    {text: 'Interest', style: 'main_info1'},
                    {text: 'Loan Savings', style: 'main_info1'},
                    {text: 'Total', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.interest, style: 'main_info'},
                    {text: this.loan_savings, style: 'main_info'},
                    {text: this.total, style: 'main_info'},
                  ]
                },
                {
                  columns: [
                    {text: 'Loan Insurance', style: 'main_info1'},
                    {text: 'Loan Processing Fee', style: 'main_info1'},
                    {text: 'Mortuary Insurance', style: 'main_info1'},
                    {text: 'Foundation', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.loan_insurance, style: 'main_info'},
                    {text: this.loan_processing_fee, style: 'main_info'},
                    {text: this.mortuary_insurance, style: 'main_info'},
                    {text: this.foundation, style: 'main_info'},
                  ]
                },
                {
                  columns: [
                    {text: 'NMAPI', style: 'main_info1'},
                    {text: 'Share Capital', style: 'main_info1'},
                    {text: 'Regular Savings', style: 'main_info1'},
                    {text: 'MBAI', style: 'main_info1'},
                    {text: 'Total Net', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.formatPrice(this.nmapi), style: 'main_info'},
                    {text: this.formatPrice(this.share_capital), style: 'main_info'},
                    {text: this.formatPrice(this.regular_savings), style: 'main_info'},
                    {text: this.formatPrice(this.mbai), style: 'main_info'},
                    {text: this.total_net, style: 'main_info'},
                  ]
                },
              ]
            },
            ' ',
            'CO-MAKER INFOMATION',
            {
              stack: [
                {
                  columns: [
                    {text: 'Co-Maker Last Name', style: 'main_info1'},
                    {text: 'Co-Make First Name', style: 'main_info1'},
                    {text: 'Co-Maker Middle Name', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.last_name_co, style: 'main_info'},
                    {text: this.first_name_co, style: 'main_info'},
                    {text: this.middle_name_co, style: 'main_info'},
                  ]
                },
                {
                  columns: [
                    {text: 'Permanent Address', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.permanent_address_co, style: 'main_info'},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'aa_for'
                    },
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Signature of Approver', style: 'aa_placeholder'},
                    {text: 'Coop Representative', style: 'cashier_placeholder'},
                    {text: 'Branch Manager/General Manager', style: 'cashier_placeholder'},
                  ]
                },
              ]
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info1: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              color: 'red',
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
